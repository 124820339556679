// Colors
$gray-color: #f2f2f2;
$black-color: #292929;
$light-black-color: #494949;
$light-white-color: #dcdcdc;
$lighter-black-color: #767676;
$white-color: #ffffff;
$dark-gray-color: darken($gray-color, 10%);
$gray-light: #636c72;


// Pallete colors
$primary-color: rgba(0,0,0,0.8);
$danger-color: #F44336;
$success-color: #4CAF50;
$warning-color: #FF9800;
$info-color: #607D8B;


// Fonts
$st-body-font: 'Poppins', sans-serif;
$st-heading-font: 'Poppins', sans-serif;
$st-heading-font-weight: 400;
